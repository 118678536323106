import { Controller } from "stimulus"
import { targets } from "../targets"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [...targets]

  initialize() {}

  validateBillingName() {
    let isValid = false
    const container = this.billingFirstNameContainerTarget
    const tag = document.querySelector(".remove-child-LastName")
    tag && container.removeChild(tag)
    if (!this.inputBillingNameTarget.value) {
      this.inputBillingNameTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("Billing Name is required")
      tag.append(text)
      tag.classList.add("remove-child-LastName")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  validateBillingLastName() {
    let isValid = false
    const container = this.billingLastNameContainerTarget
    const tag = document.querySelector(".remove-child-name")

    tag && container.removeChild(tag)
    if (!this.inputBillingLastNameTarget.value) {
      this.inputBillingLastNameTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("Last Name is required")
      tag.append(text)
      tag.classList.add("remove-child-name")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  validateBillingAddress() {
    let isValid = false
    const container = this.billingAddressContainerTarget
    const tag = document.querySelector(".remove-child-address")

    tag && container.removeChild(tag)
    if (!this.inputBillingAddressTarget.value) {
      this.inputBillingAddressTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("Address required")
      tag.append(text)
      tag.classList.add("remove-child-address")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  validateBillingCity() {
    let isValid = false
    const container = this.billingCityContainerTarget
    const tag = document.querySelector(".remove-child-city")
    tag && container.removeChild(tag)
    if (!this.inputBillingCityTarget.value) {
      this.inputBillingCityTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("city required")
      tag.append(text)
      tag.classList.add("remove-child-city")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  validateSponsoredProject() {
    let isValid = false

    if (document.querySelector("#sponsoredProject")) {
      const container = this.sponsoredProjectContainerTarget
      const tag = document.querySelector(".remove-child-sponsoredProject")
      tag && container.removeChild(tag)
      if (!this.inputSponsoredProjectTarget.value) {
        this.inputSponsoredProjectTarget.classList.add("error-input")
        const tag = document.createElement("p")
        const text = document.createTextNode("sponsored project required")
        tag.append(text)
        tag.classList.add("remove-child-sponsoredProject")
        tag.classList.add("error-input")
        container.appendChild(tag)
        return isValid
      }
    }
    isValid = true
    return isValid
  }

  validateBillingState() {
    let isValid = false
    const container = this.billingStateContainerTarget
    const tag = document.querySelector(".remove-child-State")
    tag && container.removeChild(tag)
    if (!this.inputBillingStateTarget.value) {
      this.inputBillingStateTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("state required")
      tag.append(text)
      tag.classList.add("remove-child-State")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  validateBillingPhone() {
    var element =  document.getElementById('donation_billing_phone_number')
    let isValid = false
    if (typeof(element) != 'undefined' && element != null) {
      const container = this.billingPhoneContainerTarget
      const tag = document.querySelector(".remove-child-phone")
      tag && container.removeChild(tag)
      if (!this.inputBillingPhoneNumberTarget.value) {
        this.inputBillingPhoneNumberTarget.classList.add("error-input")
        const tag = document.createElement("p")
        const text = document.createTextNode("phone required")
        tag.append(text)
        tag.classList.add("remove-child-phone")
        tag.classList.add("error-input")
        container.appendChild(tag)
        return isValid
      }
      isValid = true
      return isValid
    } else {
      isValid = true
      return isValid
    }
  }

  validateBillingZipCode() {
    let isValid = false
    const container = this.billingZipCodeContainerTarget
    const tag = document.querySelector(".remove-child-zipCode")
    tag && container.removeChild(tag)
    if (!this.inputBillingZipCodeTarget.value) {
      this.inputBillingZipCodeTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("zipCode required")
      tag.append(text)
      tag.classList.add("remove-child-zipCode")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  validateEmail() {
    let isValid = false
    const container = this.billingEmailContainerTarget
    const tag = document.querySelector(".remove-child-email")
    tag && container.removeChild(tag)
    const regexEmail =
      /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!regexEmail.test(this.inputBillingEmailTarget.value)) {
      const tag = document.createElement("p")
      const text = document.createTextNode("Send Email Correct")
      tag.append(text)
      tag.classList.add("remove-child-email")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }


  handleAmountClick(e) {
    const inputs = document.querySelectorAll(".amount-value")
    const container = this.amountContainerTarget
    const tagAmountError = document.querySelector(
      ".remove-child-amount-required"
    )
    tagAmountError && container.removeChild(tagAmountError)

    inputs.forEach((input) => input.classList.remove("activeValue"))
    e.target.classList.add("activeValue")
    if (e.target.nodeName === "IMG") {
      const spanAmount = e.target.parentNode
      spanAmount.classList.add("activeValue")
      this.donationAmount = spanAmount.getAttribute("data-value")
    } else {
      this.donationAmount = e.target.getAttribute("data-value")
    }

    this.amountTarget.value = Number(this.donationAmount)
    this.inputAmountTarget.value = ""
    const tag = document.querySelector(".remove-child-amount")
    tag && this.amountContainerTarget.removeChild(tag)
  }
  amountValueDonation(e) {
    const inputs = document.querySelectorAll(".amount-value")
    const container = this.amountContainerTarget
    const tag = document.querySelector(".remove-child-amount-required")
    tag && container.removeChild(tag)
    inputs.forEach((input) => input.classList.remove("activeValue"))
    if (this.inputAmountTarget.value >= 5) {
      this.amountTarget.value = this.inputAmountTarget.value
    }
  }
  amountValueMask(e) {
    let value = e.target.value
    const container = this.amountContainerTarget
    const tag = document.querySelector(".remove-child-amount")
    tag && container.removeChild(tag)

    if (value != "" && value < 5) {
      this.inputAmountTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("min value is 5")
      tag.append(text)
      tag.classList.add("remove-child-amount")
      tag.classList.add("error-input")
      container.appendChild(tag)
    }
  }

  mobilePhoneNumberMask(e) {
    const value = e.target.value.replace(/\D/g, "")
    this.inputMobilePhoneTarget.value = e.target.value
    return value
  }

  homePhoneNumberMask(e) {
    const value = e.target.value.replace(/\D/g, "")
    this.inputHomePhoneTarget.value = e.target.value
    return value
  }

  openSectionOrganization(e) {
    if (e.target.checked) {
      this.checkboxInputOrganizationTarget.value = "true"
    } else {
      this.checkboxInputOrganizationTarget.value = "false"
    }
    const modal = document.querySelector("div.modal-checkbox")
    const modalIsOpen = modal.classList.contains("modal-closed")
    if (modalIsOpen) {
      modal.classList.add("modalOpen")
      modal.classList.remove("modal-closed")
    } else {
      modal.classList.add("modal-closed")
    }
  }
  openModalAdress() {
    const modal = document.querySelector("div.modalAdress-checkbox")
    const modalIsOpen = modal.classList.contains("modal-closed")
    if (modalIsOpen) {
      modal.classList.add("modalOpen")
      modal.classList.remove("modal-closed")
    } else {
      modal.classList.add("modal-closed")
    }
  }

  billingCopy() {
    this.inputBillingNameTarget.value = this.inputFirstNameTarget.value
    this.inputBillingLastNameTarget.value = this.inputLastNameTarget.value
    this.inputBillingAddressTarget.value = this.inputAddressTarget.value
    this.inputBillingCityTarget.value = this.inputCityTarget.value
    this.inputBillingStateTarget.value = this.inputStateTarget.value
    this.inputBillingZipCodeTarget.value = this.inputZipCodeTarget.value
    this.inputBillingCountryTarget.value = this.inputCountryTarget.value
    this.inputBillingEmailTarget.value = this.userEmailContainerTarget.value

    this.validateCountry()
    this.validateEmail()
    this.validateBillingName()
    this.validateBillingLastName()
    this.validateBillingAddress()
    this.validateBillingCity()
    this.validateBillingState()
    this.validateBillingZipCode()
    this.CreditCardNumberValidate()
    this.cardHolderNameValidate()
    this.creditCardCVVCodeValidate()
    this.creditCardExpirationDateValidate()
  }
  validateOrganizationName() {
    let isValid = false
    const container = this.organizationNameContainerTarget
    const tag = document.querySelector(".remove-child-organizationName")
    tag && container.removeChild(tag)
    if (!this.inputOrganizationNameTarget.value) {
      this.inputBillingZipCodeTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("Organization Name is Required")
      tag.append(text)
      tag.classList.add("remove-child-organizationName")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  validateOrganizationEmail() {
    let isValid = false
    const container = this.organizationEmailContainerTarget
    const tag = document.querySelector(".remove-child-OrganizationEmail")
    tag && container.removeChild(tag)
    const regexEmail =
      /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!regexEmail.test(this.inputOrganizationEmailTarget.value)) {
      const tag = document.createElement("p")
      const text = document.createTextNode("Send Email Correct")
      tag.append(text)
      tag.classList.add("remove-child-OrganizationEmail")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }
  validateCountry() {
    let isValid = false
    const container = this.billingCountryContainerTarget
    const tag = document.querySelector(".remove-child-country")
    tag && container.removeChild(tag)
    if (!this.inputBillingCountryTarget.value) {
      this.inputBillingCountryTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("country required")
      tag.append(text)
      tag.classList.add("remove-child-country")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  CreditCardNumberValidate() {
    let isValid = false
    const container = this.billingCardNumberContainerTarget
    const tag = document.querySelector(".remove-child-creditCardNumber")
    tag && container.removeChild(tag)
    if (!this.inputCreditCardNumberTarget.value) {
      this.inputCreditCardNumberTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("Card Number required")
      tag.append(text)
      tag.classList.add("remove-child-creditCardNumber")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }
  cardHolderNameValidate() {
    let isValid = false
    const container = this.billingCardHolderNameContainerTarget
    const tag = document.querySelector(".remove-child-cardHolderName")
    tag && container.removeChild(tag)
    if (!this.inputCreditCardHolderNameTarget.value) {
      this.inputCreditCardHolderNameTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("Card Holder name required")
      tag.append(text)
      tag.classList.add("remove-child-cardHolderName")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  creditCardCVVCodeValidate() {
    let isValid = false
    const container = this.billingCVVCodeContainerTarget
    const tag = document.querySelector(".remove-child-CVVCode")
    tag && container.removeChild(tag)
    if (!this.inputCreditCardCVVCodeTarget.value) {
      this.inputCreditCardCVVCodeTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("CVV Code required")
      tag.append(text)
      tag.classList.add("remove-child-CVVCode")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  creditCardExpirationDateValidate() {
    let isValid = false
    const container = this.billingExpirationDateContainerTarget
    const tag = document.querySelector(".remove-child-ExpirationDate")
    tag && container.removeChild(tag)
    if (!this.inputCreditCardExpirationTarget.value) {
      this.inputCreditCardExpirationTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode(
        "Credit Card Expiration Date required"
      )
      tag.append(text)
      tag.classList.add("remove-child-ExpirationDate")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  validateCheckboxInput() {
    let isValid = true
    if (this.checkboxInputOrganizationTarget.value === "true") {
      const OrganizationNameValid = this.validateOrganizationName()
      const OrganizationEmailValid = this.validateOrganizationEmail()

      if (!OrganizationNameValid || !OrganizationEmailValid) {
        isValid = false
      }
    }
    return isValid
  }
  scrollTop() {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  validateGemName() {
    let isValid = false

    if (document.querySelector("#gemName")) {
      const container = this.gemNameContainerTarget
      const tag = document.querySelector(".remove-child-gemName")
      tag && container.removeChild(tag)
      if (!this.inputGemNameTarget.value) {
        this.inputGemNameTarget.classList.add("error-input")
        const tag = document.createElement("p")
        const text = document.createTextNode("gem name required")
        tag.append(text)
        tag.classList.add("remove-child-gemName")
        tag.classList.add("error-input")
        container.appendChild(tag)
        return isValid
      }
    }
    isValid = true
    return isValid
  }

  validateBirthDate() {
    let isValid = false
    if (document.querySelector("#birthDate")) {
      const container = this.birthDateContainerTarget
      const tag = document.querySelector(".remove-child-birthDate")
      tag && container.removeChild(tag)
      if (this.inputBirthDateTarget.value) {
        const BirthDatevalue = this.inputBirthDateTarget.value
        const mm = BirthDatevalue.slice(0, 2)
        const dd = BirthDatevalue.slice(3, 5)

        if (
          parseInt(dd) > 31 ||
          parseInt(mm) > 12 ||
          BirthDatevalue.length < 5 ||
          parseInt(dd) < 1 ||
          parseInt(mm) < 1
        ) {
          this.inputBirthDateTarget.classList.add("error-input")
          const tag = document.createElement("p")
          const text = document.createTextNode("Insert correct date")
          tag.append(text)
          tag.classList.add("remove-child-birthDate")
          tag.classList.add("error-input")
          container.appendChild(tag)
          return isValid
        }
      }
    }
    isValid = true
    return isValid
  }

  checkValidateMemorialAndHonor(e) {
    if (e.target.checked) {
      this.checkboxInputMemorialAndHonorTarget.value = "true"
    } else {
      this.checkboxInputMemorialAndHonorTarget.value = "false"
    }
  }

  contributeAmountEveryMemorialAndHonorValidate() {
    let isValid = false
    const container = this.contributeAmountContainerTarget
    const tag = document.querySelector(".remove-child-contrinuteAmount")
    tag && container.removeChild(tag)
    if (
      !this.contributeAmountTarget.value ||
      this.contributeAmountTarget.value < 1
    ) {
      this.contributeAmountTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("required or min value is 1")
      tag.append(text)
      tag.classList.add("remove-child-contrinuteAmount")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }

  memorialAndHonorFromValidate() {
    let isValid = false

    if (document.querySelector("#memorialAndHonorFrom")) {
      if (this.checkboxInputMemorialAndHonorTarget.value === "true") {
        const contributeAmount =
          this.contributeAmountEveryMemorialAndHonorValidate()
        if (contributeAmount == false) {
          return isValid
        }
      }

      const container = this.memberInformationContainerTarget
      const tag = document.querySelector(".remove-child-memorialAndHonorFrom")
      tag && container.removeChild(tag)
      if (!this.inputMemberInformationMemorialAndHonorTarget.value) {
        this.inputMemberInformationMemorialAndHonorTarget.classList.add(
          "error-input"
        )
        const tag = document.createElement("p")
        const text = document.createTextNode("field required")
        tag.append(text)
        tag.classList.add("remove-child-memorialAndHonorFrom")
        tag.classList.add("error-input")
        container.appendChild(tag)
        return isValid
      }
    }
    isValid = true
    return isValid
  }
  addOnFocusOutEventOnInputsFields() {
    if (document.querySelector("#memorialAndHonorFrom")) {
      if (this.checkboxInputMemorialAndHonorTarget.value === "true") {
        this.contributeAmountTarget.addEventListener("keyup", () => {
          this.contributeAmountEveryMemorialAndHonorValidate()
        })
      }
    }
    if (document.querySelector("#gemName")) {
      this.inputGemNameTarget.addEventListener("keyup", () => {
        this.validateGemName()
      })
    }
    if (document.querySelector("#sponsoredProject")) {
      this.inputSponsoredProjectTarget.addEventListener("keyup", () => {
        this.validateSponsoredProject()
      })
    }
    if (document.querySelector("#memorialAndHonorFrom")) {
      this.inputMemberInformationMemorialAndHonorTarget.addEventListener(
        "keyup",
        () => {
          this.memorialAndHonorFromValidate()
        }
      )
    }
    this.inputBillingEmailTarget.addEventListener("keyup", () => {
      this.validateEmail()
    })
    this.inputBillingNameTarget.addEventListener("keyup", () => {
      this.validateBillingName()
    })
    this.inputBillingLastNameTarget.addEventListener("keyup", () => {
      this.validateBillingLastName()
    })
    this.inputBillingAddressTarget.addEventListener("keyup", () => {
      this.validateBillingAddress()
    })
    this.inputBillingCityTarget.addEventListener("keyup", () => {
      this.validateBillingCity()
    })
    this.inputBillingStateTarget.addEventListener("keyup", () => {
      this.validateBillingState()
    })
    this.inputBillingZipCodeTarget.addEventListener("keyup", () => {
      this.validateBillingZipCode()
    })
    this.inputBillingCountryTarget.addEventListener("change", () => {
      this.validateCountry()
    })
    this.amountTarget.addEventListener("change", () => {
      this.amountRequired()
    })
    this.inputCreditCardNumberTarget.addEventListener("keyup", () => {
      this.CreditCardNumberValidate()
    })
    this.inputCreditCardHolderNameTarget.addEventListener("keyup", () => {
      this.cardHolderNameValidate()
    })
    this.inputCreditCardExpirationTarget.addEventListener("keyup", () => {
      this.creditCardExpirationDateValidate()
    })
    this.inputCreditCardCVVCodeTarget.addEventListener("keyup", () => {
      this.creditCardCVVCodeValidate()
    })
  }

  amountRequired() {
    let isValid = false
    const container = this.amountContainerTarget
    const customAmount = this.amountTarget.value
    const tag = document.querySelector(".remove-child-amount-required")
    tag && container.removeChild(tag)

    if (customAmount == "") {
      this.inputAmountTarget.classList.add("error-input")
      const tag = document.createElement("p")
      const text = document.createTextNode("required")
      tag.append(text)
      tag.classList.add("remove-child-amount-required")
      tag.classList.add("error-input")
      container.appendChild(tag)
      return isValid
    }
    isValid = true
    return isValid
  }
  openModalDonation() {
    this.btnConfirmFormTarget.innerHTML = "Confirm Donation &nbsp; &#10084;"
    this.messageAwaitDonationTarget.innerText = "Message"
    $("#modalOpenDonationContainer").modal()
  }
  btnSubmitForm(event) {
    event.preventDefault()
    const memorialAndHonorFromValidation = this.memorialAndHonorFromValidate()

    const amountCustomValidation = this.amountRequired()
    const customAmount = this.amountTarget.value

    const checkboxOrganizationValidation = this.validateCheckboxInput()

    const validationGemName = this.validateGemName()
    const validationBirthDate = this.validateBirthDate()

    const billingCountryValidation = this.validateCountry()
    const billingValidationEmail = this.validateEmail()
    const billingValidationPhone = this.validateBillingPhone()
    const billingNameValidation = this.validateBillingName()
    const billingLastNameValidation = this.validateBillingLastName()
    const billingAddressValidation = this.validateBillingAddress()
    const billingCityValidation = this.validateBillingCity()
    const billingStateValidation = this.validateBillingState()
    const billingZipCodeValidation = this.validateBillingZipCode()
    const creditCardNumberValidation = this.CreditCardNumberValidate()
    const cardHolderNameValidation = this.cardHolderNameValidate()
    const creditCardCVVCodeValidation = this.creditCardCVVCodeValidate()
    const cardExpirationDateValidation = this.creditCardExpirationDateValidate()
    const validateSponsoredProjectValidation = this.validateSponsoredProject()

    if (
      billingNameValidation &&
      billingLastNameValidation &&
      billingAddressValidation &&
      billingCityValidation &&
      billingStateValidation &&
      billingZipCodeValidation &&
      checkboxOrganizationValidation &&
      billingValidationEmail &&
      billingValidationPhone &&
      validationGemName &&
      amountCustomValidation &&
      creditCardNumberValidation &&
      cardHolderNameValidation &&
      cardExpirationDateValidation &&
      memorialAndHonorFromValidation &&
      validateSponsoredProjectValidation &&
      validationBirthDate
    ) {
      this.btnConfirmFormTarget.innerText = ""
      this.btnConfirmFormTarget.disabled = true
      this.btnConfirmFormTarget.innerText = "Submitting..."
      this.messageAwaitDonationTarget.innerText = "Loading Donation"
      // const divSpinner = document.createElement("div")
      // divSpinner.classList.add("spin")
      // this.btnConfirmFormTarget.appendChild(divSpinner)

       Rails.fire(this.donationsFormTarget, "submit")
    } else {
      this.btnConfirmFormTarget.disabled = false
      this.scrollTop()
      $.modal.close()

      this.addOnFocusOutEventOnInputsFields()
    }
  }

  paymentSuccess(event) {
    this.btnConfirmFormTarget.disabled = false
    const [data, status, xhr] = event.detail
    window.location.href = xhr.response
  }
}
